<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              La Formation Pro
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Réinitialisation de mot de passe
          </p>
          <p class="mb-2">
            Vous avez réinitialisé votre mot de passe. Saisissez votre nouveau de mot de passe dans le formulaire ci-dessous.
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
          >
            <!-- new password -->
            <v-text-field
              v-model="newPassword"
              :type="isNewPasswordVisible ? 'text' : 'password'"
              :rules="[v => !!v || 'Requis',(newPassword === cPassword) || 'Les mots de passe doivent correspondre']"
              :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
              label="Nouveau mot de passe"
              outlined
              dense
              hint="Le mot de passe doit faire au moins 8 caractères."
              persistent-hint
              @click:append="isNewPasswordVisible = !isNewPasswordVisible"
            ></v-text-field>

            <!-- confirm password -->
            <v-text-field
              v-model="cPassword"
              :rules="[v => !!v || 'Requis',(newPassword === cPassword) || 'Les mots de passe doivent correspondre']"
              :type="isCPasswordVisible ? 'text' : 'password'"
              :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
              label="Confirmer le nouveau mot de passe"
              outlined
              dense
              class="mt-3"
              @click:append="isCPasswordVisible = !isCPasswordVisible"
            ></v-text-field>

            <v-btn
              block
              :disabled="disableBtn"
              color="primary"
              class="mt-6"
              @click="forgotPass"
            >
              Enregistrer
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <a
            color="primary"
            class="mt-5"
            @click="$router.push({name:'login'})"
          >
            Retour à l'identification
          </a>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  data() {
    return {
      disableBtn: false,
      valid: true,
      newPassword: '',
      cPassword: '',
      isNewPasswordVisible: false,
      isCPasswordVisible: false,
      token: '',
    }
  },
  mounted() {
    this.$store.dispatch('logout')
    this.token = this.$route.query.token
  },
  setup() {
    return {
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    forgotPass() {
      if (this.$refs.form.validate()) {
        this.disableBtn = true
        this.$http.post(`${process.env.VUE_APP_API_URL}/users-permissions/reset-password/`, { token: this.token, newPassword: this.newPassword })
          .then(res => {
            if (res.status === 200) {
              this.$store.commit('snackMe', {
                color: 'success',
                text: 'Votre mot de passe a bien été mis à jour, vous pouvez vous connecter.',
                value: true,
              })
              this.$refs.form.reset()
            }
          })
          .catch(err => {
            if (err.response.status === 404) {
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'Le jeton ne correspond à aucun utilisateur',
                value: true,
              })
            } else {
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'Erreur lors de la mise à jour du mot de passe',
                value: true,
              })
            }
            console.log(err)
          })
          .finally(() => {
            this.disableBtn = false
          })
      } else {
        this.$refs.form.validate()
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
